<template>
  <div class="login_box" v-loading.fullscreen.lock="fullscreenLoading">
    <div class="login_out"></div>
    <div class="login_content">
      <p class="login_logo">
        <img src="../assets/icon/cc-logo-white.svg" alt />
      </p>
      <div class="login_infor">
        <div class="login_small">
          <h1>Welcome Back</h1>
          <el-input v-model="email" placeholder="Please enter your email" />
          <el-input
            v-model="password"
            placeholder="Please enter your email password"
            show-password
            @keyup.enter.native="login"
            class="inpassword"
          />
          <div class="aboutP">
            <el-checkbox v-model="checked">Remember me</el-checkbox>
            <a href="/forget-password" class="forgetP">Forget Password?</a>
          </div>
          <el-button type="primary" @click="login">LOGIN</el-button>
          <hr class="Underline" />
          <div class="Otherparty">
            <div>
              <div class="google" id="sign" @click="googleLogin">
                <img src="../assets/img/20190812161152.png" width="100%" />
              </div>
              <!-- <div id="sign" @click="facebookLogin">
                <img src="../../assets/img/20190812161208.png" height="20px" width="100%" />
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="move">
        <p class="log-title">Sign in to C&C</p>
        <p class="tip">Enter your detals below</p>
        <div id="sign" class="google google-log" @click="googleLogin">
          <img src="../assets/img/20190812161152.png" width="100%" />
        </div>
        <el-divider>OR</el-divider>
        <div>
          <el-input v-model="email" placeholder="Please enter your email" />
          <el-input
            v-model="password"
            placeholder="Please enter your email password"
            show-password
            @keyup.enter.native="login"
            class="inpassword"
          />
          <div class="about-row row">
            <el-checkbox v-model="checked">Remember me</el-checkbox>
            <a href="/forget-password" class="forgetP">Forget Password?</a>
          </div>
          <el-button class="btn primary-button" @click="login"
            >Sign in</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { apiLogin, apiThirdparty, apiThirdData } from "../API/api";
import { facebookLogin, googleLogin } from "../API/utils";
import storage from "../js/storage";
import { Base64 } from "js-base64";
export default {
  data() {
    return {
      email: "",
      password: "",
      checked: true,
      fullscreenLoading: false,
    };
  },
  methods: {
    checkout(res) {
      // 可以登录
      storage.setItem("token", res.token, 7);
      // storage.setItem("type",res.user_type,7)
      storage.setItem("isType", res.is_admin, 7);
      storage.setItem("name", res.first_name + " " + res.last_name, 7);
      sessionStorage.setItem("uersId", Base64.encode(`${res?.user_id ?? ""}`));
      if (!res.email || !res.first_name || !res.last_name) {
        // 基本信息未填写
        this.$router.push({ name: "person", params: { email: res.email } });
      } else {
        // 登录成功
        if (this.$store.state.goBack) {
          // 需要回退到上一个页面
          var pathName = sessionStorage.getItem("path");
          if (
            pathName === "resources-restaurants" ||
            pathName === "resources-neigheorhoods" ||
            pathName === "resources-covid" ||
            pathName === "blogs" ||
            pathName === "profile" ||
            pathName === "agents"
          ) {
            if (res.is_admin) {
              this.$router.go(-1);
              this.$store.commit("setGoBack", false);
            } else {
              this.$router.push("/dashboard");
            }
            sessionStorage.removeItem("path");
          } else {
            this.$router.go(-1);
            this.$store.commit("setGoBack", false);
            sessionStorage.removeItem("path");
          }
        }
        this.$router.push("/dashboard");
      }
    },
    login() {
      if (this.email.trim() !== "" && this.password !== "") {
        this.fullscreenLoading = true;
        storage.removeItem("hello");
        apiLogin({
          email: this.email,
          password: this.password,
          user_type: "pm",
        })
          .then((res) => {
            this.checkout(res);
          })
          .catch((err) => {
            this.$message.error(
              err.response.data && err.response.data.detail
                ? err.response.data.detail
                : "Login failed, please login again."
            );
          })
          .finally(() => {
            this.fullscreenLoading = false;
          });
      } else {
        this.$message.error("Please enter your mailbox and password.");
      }
    },
    // google
    googleLogin() {
      googleLogin()
        .then((res) => {
          return apiThirdparty({
            token: JSON.parse(localStorage.getItem("hello"))["google"][
              "id_token"
            ],
            source: "google",
            user_type: "pm",
          });
        })
        .then((res) => {
          this.checkout(res);
          this.thirdData(res.user_id);
        })
        .catch((err) => {
          this.$message.error(
            err.response.data && err.response.data.detail
              ? err.response.data.detail
              : "Login failed, please login again."
          );
        });
    },
    // facebook
    facebookLogin() {
      facebookLogin()
        .then((res) => {
          return apiThirdparty({
            token: res.authResponse.access_token,
            source: "facebook",
            user_type: "pm",
          });
        })
        .then((res) => {
          this.checkout(res);
        })
        .catch((err) => {
          this.$message.error(
            err.response.data && err.response.data.detail
              ? err.response.data.detail
              : "Login failed, please login again."
          );
        });
    },
    thirdData(id) {
      let obj = {
        user: id,
        access_token: JSON.parse(localStorage.getItem("hello"))["google"][
          "access_token"
        ],
      };
      apiThirdData(obj);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../css/button.scss";
.login_box {
  width: 100%;
  height: 100vh;
  font-family: "Roboto-Regular", sans-serif;
  position: relative;
  .login_out {
    height: 100%;
    width: 100%;
    background-image: url("../assets/img/20191218172529.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.8;
    -webkit-filter: blur(5px); /* Chrome, Opera */
    -moz-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(3px);
    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius=10, MakeShadow=false); /* IE6~IE9 */
  }
  .login_content {
    width: 500px;
    min-height: 450px;
    position: absolute;
    top: calc(50% - 250px);
    left: calc(50% - 225px);
    .login_logo {
      text-align: left;
    }

    .google {
      cursor: pointer;
    }

    .login_infor {
      //pc
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 5px 20px #33333340;
      border-radius: 10px;
      padding: 40px;
      width: calc(100% - 80px);
      height: 400px;
      h1 {
        font-family: "Roboto-Medium", sans-serif;
        font-size: 34px;
        text-align: left;
        color: #678993;
        margin: 0 0 20px 0;
      }
      /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
      /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #678993;
        border-color: #678993;
        border-radius: 50%;
      }
      /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
        color: #333333;
      }
      /deep/ .el-checkbox__inner {
        border-radius: 50%;
        border-color: #dcdfe6;
      }
      /deep/ .el-checkbox__inner:hover {
        border-color: #678993;
      }
      .aboutP {
        display: flex;
        justify-content: space-between;
        margin: 20px 0 30px 0;
      }
      .inpassword {
        margin-top: 10px;
      }
      .forgetP {
        text-decoration: none;
        font-size: 14px;
        color: #678993;
      }
      .el-button {
        width: 100%;
        background-color: #678993;
        height: 50px;
        border-color: #678993;
        font-size: 24px;
      }
      .Underline {
        background-color: #e5e5e5;
        margin: 10px 0;
        border: none;
        height: 1px;
      }
    }
  }
  /deep/ .el-input__inner {
    line-height: normal;
    height: 40px;
    font-family: "Roboto-Medium", sans-serif;
  }
  .move {
    display: none;
    background-color: #ffffff;
    height: calc(100% - 50px);
    padding: 20px 20px 0 20px;
    .log-title {
      color: #36425d;
      font-size: 24px;
      text-align: left;
      font-family: "Roboto-Bold";
      margin-bottom: 0;
    }
    .tip {
      text-align: left;
      font-family: "Roboto-Regular";
      font-size: 14px;
    }
    .google-log {
      margin-top: 20px;
    }
    a {
      color: #678993;
    }
    /deep/ .el-divider--horizontal {
      margin: 40px 0;
    }
    /deep/ .el-input {
      margin-bottom: 20px;
    }
    .about-row {
      justify-content: space-between;
      margin-bottom: 20px;
    }
    .btn {
      width: 100%;
      font-size: 20px;
    }
  }
  @media (max-width: 992px) {
    .login_content {
      left: 0;
      top: 30px;
      width: 100%;
      height: 100%;
      .login_infor {
        display: none;
      }
      .login_logo {
        text-align: center;
        img {
          width: 60%;
        }
      }
    }
    .login_out {
      background-size: contain;
      opacity: 0.8;
    }
    .move {
      display: block;
    }
  }
}
</style>
